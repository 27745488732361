import Trans from 'next-translate/Trans';
import {useEffect, useState} from 'react';
import {styled, withTheme} from 'styled-components';
import {SecureClockPlaceholder} from '../../components/animation-placeholders/secure-clock-placeholder';
import {SafeDepositPlaceholder} from '../../components/animation-placeholders/safe-deposit-placeholder';
import {HomeBanner} from '../../components/home-banner';
import {HomeFooter} from '../../components/home-footer';
import {HomeHeader} from '../../components/home-header-teloa';
import {LoadingIndicator} from '../../components/loading-indicator';
import {CanNotRenderSeo} from '../../components/seo/fonoma-seo';
import {HomeLandingSeo} from '../../components/seo/home-landing-seo';
import {ZigZag} from '../../components/zig-zag/zig-zag';
import {ZigZagItemClass} from '../../components/zig-zag/zig-zag-item';
import {PAGE_EVENTS, SERVICES} from '../util/constants';
import {ExtendedLandingPageProps} from '../util/landing-props';
import {CountryServerData} from '../util/utilities/get-static-country-props';
import {getHomeLandingIdentifyData} from '../analytics/get-home-landing-identify-data';
import {GradientType} from '../../components/header-noise';
import dynamic from 'next/dynamic';
import {PersonData, getUserData} from '../util/utilities/get-user-data';
import Cookies from 'universal-cookie';
import {analytics} from '../analytics/analytics';
import {AllUSCarriers} from '../../components/us-all-carriers';
import {mediaQueries} from '../common';
import {getPromoUrl} from '../util/get-promo-url';

/*
 * We need to disable server-side rendering (SSR) for components that depend
 * on an external script waiting to load in the browser.
 * https://nextjs.org/docs/messages/react-hydration-error
 */
const LandingsReviewsIoCarouselWidget = dynamic(
    () =>
        import('../../components/reviews-io/reviews-io-carousel-widget').then(
            (mod) => mod.LandingsReviewsIoCarouselWidget
        ),
    {
        ssr: false,
    }
);

export const BlackLink = styled.a`
    color: ${({theme}) => theme.colors.ballena};
`;

const StyledZigZag = styled(ZigZag)`
    padding: 0 240px 48px;

    ${mediaQueries.lessThan('small')`         
        padding: 24px 16px 24px;
    `}
`;

const Link = styled.a`
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.ballena};
    margin-top: ${({theme}): string => theme.spacing.m};
    text-decoration: none;

    &:first-child {
        margin-top: 0;
    }
`;

const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;

const _TeloaHomeLandingPageTemplate = (
    props: ExtendedLandingPageProps<CountryServerData>
) => {
    const {theme, serverData, shouldRender, isAnnounced, promo, operator} =
        props;
    const {t, lang} = props.i18n;
    const {
        countryPrefix,
        mobilePrefixes,
        cellphoneNumberLength,
        acquisitionDiscountAmount,
    } = serverData;

    const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;

    const PROMO_URL = getPromoUrl(lang);

    const canonicalUrl = `${SERVER_BASE_URL}/${SERVICES.PHONE}`;

    const [loading, setLoading] = useState(false);
    const [personData, setPersonData] = useState({} as PersonData);
    const [toServiceUrl, setToServiceUrl] = useState('');

    useEffect(() => {
        const toServiceUrl = `${SERVER_BASE_URL}/${SERVICES.PHONE}${document.location.search}`;

        setToServiceUrl(toServiceUrl);

        const {analytics} = props;
        void analytics.page(undefined, PAGE_EVENTS.LANDING_HOME_PAGE, {
            operator,
            isAnnounced,
            promo,
        });

        const cookies = new Cookies();
        const jwt = cookies.get<string>('jwt');
        if (jwt) {
            setLoading(true);
            void getUserData().then((response) => {
                setPersonData(response);
                setLoading(false);
                if (response.isLoggedIn) {
                    const person = response.personDetails;
                    const [props, opts] = getHomeLandingIdentifyData(person);
                    void analytics.identify(person.id, props, opts);
                }
            });
        }
    }, [isAnnounced, operator, promo, props]);

    if (!shouldRender) {
        return (
            <>
                <CanNotRenderSeo title={t('landings:impossible-to-render')} />
                <h1>{t('landings:page-blank')}</h1>
            </>
        );
    }

    const HELP_URL = `${process.env.NEXT_PUBLIC_HELP_URL}/${lang}/`;
    const headerTitle = <Trans i18nKey="home_landing:title-teloa" />;

    //Texts for phone service type
    const buttonText = t('home_landing:refill-action');
    const labelText = t('home_landing:send-recharge-us-label');
    const descriptionKey = 'description-teloa';
    const serviceComponents = [
        <b key={0} />,
        <b key={1} />,
        <BlackLink href={toServiceUrl} key={2} />,
    ];

    const placeholders = [
        <SecureClockPlaceholder key={0} />,
        <SafeDepositPlaceholder key={1} />,
    ];

    const zigzagItems = [];
    zigzagItems.push(
        new ZigZagItemClass(
            null,
            placeholders[0],
            (
                <Trans
                    i18nKey={`home_landing:zigzag_item_${0}.${descriptionKey}`}
                    components={serviceComponents}
                />
            ),
            t(`home_landing:zigzag_item_${0}.title`),
            '',
            ''
        )
    );

    zigzagItems.push(
        new ZigZagItemClass(
            null,
            placeholders[1],
            (
                <Trans
                    i18nKey={`home_landing:zigzag_item_${1}.description-teloa`}
                    components={[
                        <b key={0} />,
                        <b key={1} />,
                        <BlackLink href="/" key={2} />,
                    ]}
                />
            ),
            t(`home_landing:zigzag_item_${1}.title-teloa`),
            '',
            ''
        )
    );

    const footerRightLinks = [];
    const footerLeftLinks = [];

    footerLeftLinks.push(
        <Link href={`${SERVER_BASE_URL}/about`}>{t('footer:about-teloa')}</Link>
    );
    footerLeftLinks.push(<Link>{t('footer:blog')}</Link>);
    footerLeftLinks.push(<Link href={HELP_URL}>{t('footer:help')}</Link>);

    if (PROMO_URL) {
        footerRightLinks.push(
            <Link href={PROMO_URL}>{t('footer:promotions')}</Link>
        );
    } else {
        footerRightLinks.push(<a target="_self">{t('footer:promotions')}</a>);
    }

    footerRightLinks.push(
        <Link href={`${SERVER_BASE_URL}/`}>
            {t('footer:cellphone-refills')}
        </Link>
    );
    footerRightLinks.push(
        <Link href={`${SERVER_BASE_URL}/invitation`}>
            {t('footer:invite-your-friends')}
        </Link>
    );

    return (
        <>
            {loading && (
                <LoadingIndicator opacity={1} color={theme.colors.arroz} />
            )}

            <HomeLandingSeo
                title={t('home_landing:htmlTitle-teloa')}
                canonicalUrl={canonicalUrl}
                description={t('metas:description-key-teloa')}
                ogTitle={t('metas:og-title-teloa')}
                ogDescription={t('metas:og-description-teloa')}
            />

            <HomeHeader
                title={headerTitle}
                labelText={labelText}
                buttonText={buttonText}
                personData={personData}
                serviceName={SERVICES.PHONE}
                cellphoneNumberLength={cellphoneNumberLength}
                mobilePrefixes={mobilePrefixes}
                countryPrefix={countryPrefix}
                analytics={analytics}
            />

            <StyledZigZag items={zigzagItems} zigzag={true} />

            <AllUSCarriers />

            <HomeBanner
                heading={t('home_landing:banner-info') + PROJECT_NAME}
                title={t('home_landing:banner-title-teloa', {
                    discount: `$${acquisitionDiscountAmount}`,
                })}
                actionText={t('home_landing:refill-action')}
                actionLink="" //TODO: Where to go?
                gradient={GradientType.TeloaHomeAcqGradient}
                subtitleDisabled={true}
            />

            <LandingsReviewsIoCarouselWidget
                lang={lang}
                dataStore="teloa.com"
                staticHeader={t('home_landing:reviews')}
            />

            <HomeFooter
                rightLinks={footerRightLinks}
                leftLinks={footerLeftLinks}
            />
        </>
    );
};

export const TeloaHomeLandingPageTemplate = withTheme(
    _TeloaHomeLandingPageTemplate
);
