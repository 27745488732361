import React from 'react';
import {styled} from 'styled-components';
import {mediaQueries, typographyHeading4Styles} from '../modules/common/styles';
import withTranslation from 'next-translate/withTranslation';
import {I18n} from '../modules/util/i18n/i18n-interface';

const Container = styled.div`
    margin-top: 7.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;

    ${mediaQueries.lessThan('small')`
        margin-top: 4rem;
    `};
`;

const Title = styled.h2`
    ${typographyHeading4Styles}
    margin-top: 0px;
    margin-bottom: 1rem;
    color: ${({theme}) => theme.colors.arroz};
`;

const LogosContainer = styled.div`
    display: grid;
    justify-content: center;

    ${mediaQueries.lessThan('small')`
        padding: 0 24px;
        grid-template-columns: repeat(2, auto);
        place-items: center;
        grid-gap: 0 2rem;
    `};
`;

const Logo = styled.img`
    width: auto;
    object-fit: scale-down;
    grid-row: 1;
    margin: auto 2.5rem auto 0;

    &:last-child {
        margin-right: 0;
    }

    ${mediaQueries.lessThan('small')`
        width: 100%;
        grid-row: unset;
        margin: auto;
    `};
`;

const Link = styled.a`
    margin-top: 1rem;
    color: ${({theme}) => theme.colors.arroz};
`;

interface USCarrierProps {
    i18n: I18n;
}

const UnstyledUSCarriers = (props: USCarrierProps) => {
    const {t} = props.i18n;

    const logos = [
        {
            logoSrc:
                '/static/landings/miscellanea/us-carriers/t-mobile-white.webp',
            alt: 'T-Mobile',
        },
        {
            logoSrc: '/static/landings/miscellanea/us-carriers/att-white.webp',
            alt: 'AT&T',
        },
        {
            logoSrc:
                '/static/landings/miscellanea/us-carriers/metro-white.webp',
            alt: 'Metro',
        },
        {
            logoSrc:
                '/static/landings/miscellanea/us-carriers/verizon-white.webp',
            alt: 'Verizon',
        },
    ];

    return (
        <Container>
            <Title>{t('home_landing:us_carriers.title')}</Title>
            <LogosContainer>
                {logos.map((logo, index) => (
                    <Logo key={index} src={logo.logoSrc} alt={logo.alt} />
                ))}
            </LogosContainer>
            <Link href="#all-carriers">
                {t('home_landing:us_carriers.link')}
            </Link>
        </Container>
    );
};

const USCarriers = withTranslation(UnstyledUSCarriers);

export {USCarriers};
