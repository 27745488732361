
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { getStaticPaths } from '../../../modules/util/utilities/get-static-paths';
import { TeloaHomeLandingPageTemplate } from '../../../modules/template-pages/teloa-home-landing-template';
import useTranslation from 'next-translate/useTranslation';
import { analytics } from '../../../modules/analytics/analytics';
import { CountryServerData, obtainGetStaticCountryProps, } from '../../../modules/util/utilities/get-static-country-props';
import { LandingPageBaseProps } from '../../../modules/util/landing-props';
// eslint-disable-next-line react/display-name
const withI18n = (Component) => (props) => {
    const i18n = useTranslation();
    return <Component i18n={i18n} {...props}/>;
};
const HomeLandingPageTemplateWithI18n = withI18n(TeloaHomeLandingPageTemplate);
const TeloaHomeNoPromoPage = (props: LandingPageBaseProps<CountryServerData>) => {
    const { t, lang } = useTranslation();
    return (<HomeLandingPageTemplateWithI18n i18n={{ t, lang }} analytics={analytics} {...props}/>);
};
export default TeloaHomeNoPromoPage;
const getStaticProps = obtainGetStaticCountryProps();
export { getStaticPaths };

    async function __Next_Translate__getStaticProps__191e133ab8b__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/retope/home_landing_no_promo_phone/[[...timeestamp]]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191e133ab8b__ as getStaticProps }
  