import {styled} from 'styled-components';
import {mediaQueries} from '../../modules/common';

const SafeDepositPlaceholder = styled.div`
    background-image: url(/static/landings/miscellanea/safe-deposit-desktop.webp);
    background-repeat: no-repeat;
    background-position: 75% center;
    background-size: contain;
    width: 100%;
    height: 100%;

    ${mediaQueries.lessThan('small')`    
        background-image: url(/static/landings/miscellanea/safe-deposit-mobile.webp);     
        background-position: center;
        background-size: contain;
        margin-left: unset;
        height: 90%;
    `}
`;

export {SafeDepositPlaceholder};
