import React from 'react';
import {styled} from 'styled-components';
import {mediaQueries, typographyHeading2Styles} from '../modules/common/styles';
import useTranslation from 'next-translate/useTranslation';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 1128px;
    align-items: center;
    margin: 2rem auto 5rem;

    ${mediaQueries.lessThan('small')`
        margin: 0 auto 5rem;
        text-align: center;
        padding: 0 16px;
    `};
`;

const Title = styled.h2`
    ${typographyHeading2Styles}
    margin: 0px;
    color: ${({theme}) => theme.colors.ballena};
`;

const LogosContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    gap: 1rem;
    padding: 0 7.875rem;

    ${mediaQueries.lessThan('small')`
        padding: unset;
    `};
`;

const Logo = styled.img`
    width: auto;
    padding: 16px 24px;
    flex: 1 1 20%;
    justify-content: center;
    max-width: 167px;
    box-sizing: content-box;
    border-radius: 8px;
    border: solid 1px ${({theme}) => theme.colors.atun};
    object-fit: scale-down;

    ${mediaQueries.lessThan('small')`
        max-width: 33%;
    `};
`;

export const AllUSCarriers = () => {
    const {t} = useTranslation();

    const logos = [
        {
            logoSrc: '/static/landings/miscellanea/us-carriers/t-mobile.png',
            alt: 'T-Mobile',
        },
        {
            logoSrc: '/static/landings/miscellanea/us-carriers/att.png',
            alt: 'AT&T',
        },
        {
            logoSrc: '/static/landings/miscellanea/us-carriers/metro.png',
            alt: 'Metro',
        },
        {
            logoSrc: '/static/landings/miscellanea/us-carriers/cricket.png',
            alt: 'Cricket',
        },
        {
            logoSrc:
                '/static/landings/miscellanea/us-carriers/simple-mobile.png',
            alt: 'Simple Mobile',
        },
        {
            logoSrc: '/static/landings/miscellanea/us-carriers/verizon.png',
            alt: 'Verizon',
        },
    ];

    return (
        <Container>
            <Title id="all-carriers">
                {t('home_landing:us_carriers_supported')}
            </Title>
            <LogosContainer>
                {logos.map((logo, index) => (
                    <Logo key={index} src={logo.logoSrc} alt={logo.alt} />
                ))}
            </LogosContainer>
        </Container>
    );
};
