import {styled} from 'styled-components';
import {mediaQueries} from '../../modules/common';

const SecureClockPlaceholder = styled.div`
    background-image: url(/static/landings/miscellanea/secure-clock-desktop.webp);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;

    ${mediaQueries.lessThan('small')`         
        background-image: url(/static/landings/miscellanea/secure-clock-mobile.webp);
        background-position: center;
        background-size: contain;
        margin-left: unset;
        height: 90%;
    `}
`;

export {SecureClockPlaceholder};
