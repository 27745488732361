
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/retope/home_landing_no_promo_phone/[[...timeestamp]]",
      function () {
        return require("private-next-pages/retope/home_landing_no_promo_phone/[[...timeestamp]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/retope/home_landing_no_promo_phone/[[...timeestamp]]"])
      });
    }
  